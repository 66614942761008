html,
body {
    font-family: 'HelveticaNow-Regular' !important;
}

body {
    padding-bottom: 50px;
}

.nav-link {
    font-family: 'HelveticaNow-Medium' !important;
    color: #363435 !important;
    font-size: 14px;
    border-bottom: 2px solid transparent;
}

.nav-link-text-white {
    color: white !important;
}

.nav-link-bg-white {
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.5);
}

.nav-link-bg-white:hover {
    background-color: rgba(255, 255, 255);
}

.nav-link:hover {
    border-bottom: 2px solid #fff;
}

.container-home-btns {
    margin-top: 40px;
}

.jumbotron-home-bg {
    background-image: url('./imgs/portada.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
}

.btn-seccion {
    /*width: max-content;*/
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.btn-seccion>a>img {
    background-color: #1E4396;
    height: 80px;
    width: 80px;
    padding: 15px;
    border-radius: 30px;
    margin-bottom: 15px;
}

.btn-aux>div>img {
    background-color: #1E4396;
    height: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 0px;
    margin-right: 10px;
}

.btn-seccion>a,
.btn-aux>div {
    color: #1E4396;
    cursor: pointer;
}

.jumbo-encabezado-seccion {
    background-color: #1E4396;
    text-align: center;
    color: white;
    font-family: 'HelveticaNow-Regular' !important;
}

.img-encabezado {
    width: 65px;
    height: 65px;
}

h5 {
    font-family: 'HelveticaNow-Regular' !important;
    color: #1E4396;
    text-align: center;
}

p {
    font-family: 'HelveticaNow-Regular' !important;
    color: #1E4396;
    text-align: justify;
    font-size: 14px;
}

p a {
    font-weight: bold;
    color: #1E4396;
    text-decoration: underline;
}

.text-red {
    color: red;
}

ul {
    font-family: 'HelveticaNow-Regular' !important;
    color: #1E4396;
    text-align: left;
    font-size: 14px;
    padding-left: 10px;
}

ol {
    font-family: 'HelveticaNow-Regular' !important;
    color: #1E4396;
    text-align: left;
    font-size: 14px;
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

.navbar {
    position: absolute;
    z-index: 1001;
    width: 100%;
}

.info-div-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.info-div-section {
    margin-top: 15px;
    overflow: hidden;
    transition: flex 0.3s ease-out;
    height: 100%;
    flex: 1;
    max-height: 9999px;
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
}

.info-div-section.collapsed {
    margin-top: 0px;
    flex: 0;
    height: 0;
    max-height: 0;
    transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
}

.navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.atgenda-iframe {
    height: 2000px;
}

.mw-33 {
    max-width: 33%;
}

.iframe-ubicacion {
    height: 400px;
    width: 100%;
}

.error {
    border: 2px solid #FF6565;
}

.error-message {
    color: #FF6565;
    font-family: 'HelveticaNow-Regular' !important;
    margin-top: 2px;
    font-size: 12px;
}

label {
    font-size: 14px;
    margin-bottom: 4px;
    font-family: 'HelveticaNow-Regular' !important;
    color: #1E4396;
}

.form-control {
    color: #1E4396;
    font-family: 'HelveticaNow-Regular' !important;
}

.texto-azul {
    color: #1E4396;
}

.contador {
    font-size: large;
}

.form-group {
    margin-bottom: 5px;
}


.hide-recaptcha-label .grecaptcha-badge {
    display: none !important;
}

.show-recaptcha-label .grecaptcha-badge {
    display: block !important;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
    font-family: 'HelveticaNow-ExtraLight' !important;
}

.alert {
    font-size: 14px !important;
}

.cargando-vuelos {
    background-color: #ececec;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.tabla-vuelos {
    font-family: 'HelveticaNow-Regular' !important;
    font-size: 12px;
    color: #1E4396;
}

.btn-donde-bqb {
    font-size: 14px;
    margin-bottom: 2px;
}

.modal-title-atgen {
    color: #1E4396EE;
    font-size: 16px;
}

.backdrop-mapa {
    z-index: 2000 !important;
}

.modal-mapa {
    z-index: 2001 !important;
}

.size-10 {
    font-size: 10px;
}

.h-50vh {
    height: 50vh;
}

.txt-carnaval {
    color: red;
    font-size: 20px;
    margin-bottom: 2px;
}

.txt-rojo {
    color: red;
}

.btn-link {
    color: #1E4396;
}

.copa2 {
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
    color: red;
}

@media (max-width: 991.98px) {
    .navbar-collapse {
        background-color: #1E4396;
        padding: 10px;
        border-radius: 4px;
        border: 2px solid #152f6c;
    }

    .navbar-toggler {
        padding: 0px;
    }

    .nav-link {
        color: white !important;
        font-size: 16px;
        font-family: 'HelveticaNow-Regular' !important;
    }

    .jumbo-encabezado-seccion {
        padding: 30px !important;
        padding-top: 60px !important;
    }
}

@media (max-width: 767.98px) {

    .container-home-btns {
        margin-top: 0px;
    }

    .col-jumbo-portada {
        height: 30%;
    }

    .jumbotron-home-bg {
        height: 180px;
    }
}

.copa {
    font-size: 15px;
    color: red;
    
}

.texto{
    text-align: center;
    font-size: 20PX;
}

.texto_atencion{
    text-align: center;
    font-size: 16PX;
    color: red;
}
.texto_decision{
    text-align: center;
    font-size: 16PX;
}
.li{
    align-content: center;
    font-size: 16px;
}

.titulo-novedades {
    font-size: 32px;
    font-family: 'Glacial Indifference', sans-serif;
}
.titulo-novedades-fiestas {
    font-size: 25px;
    font-family: 'Glacial Indifference', sans-serif;
}
.novedades {
    font-size: 16px;
    font-family: 'Glacial Indifference', sans-serif;
}

.vip {
    display: flex;
    max-width: 100%;
    height: 320px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    background-image: url("/src/assets/imgs/vip2.png");
    background-repeat: no-repeat;
    background-size: contain;
}

@media (max-width: 767.98px) {
    .vip {
        display: none;
    }
}

.accordion .card-header h5 {
    text-align: left;
}

.modal-novedades-bg {
    background-color: #efeae5;
}

.btn-cerrar-modal {
    background: #1E4396;
    color: #fff;
    text-decoration: none;
    border: 3px solid #fff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 0px 8px;
    -webkit-box-shadow: -2px 1px 3px #ccc;
    -moz-box-shadow: -2px 1px 3px #ccc;
    box-shadow: -2px 1px 3px #ccc;
    position: absolute;
    right: 0;
    z-index: 10;
    margin: 12px;
    height: 32px;
}

.modal-novedades p,
.modal-novedades a {
    font-family: 'Glacial Indifference', sans-serif !important;
    font-size: 18px;
    color: #4458be !important;
}

.div-nov {
    border-radius: 15px;
    background-color: #1E4396;
    border-color: black;
    border-width:thin;
}; 