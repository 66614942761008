@font-face {
    font-family: 'HelveticaNow-Black';
    src: local('HelveticaNow-Black'), url(./fonts/HelveticaNowText-Black.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-BlackItalic';
    src: local('HelveticaNow-BlackItalic'), url(./fonts/HelveticaNowText-BlackItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-Bold';
    src: local('HelveticaNow-Bold'), url(./fonts/HelveticaNowText-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-ExtBdIta';
    src: local('HelveticaNow-ExtBdIta'), url(./fonts/HelveticaNowText-ExtBdIta.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-ExtraBold';
    src: local('HelveticaNow-ExtraBold'), url(./fonts/HelveticaNowText-ExtraBold.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-ExtraLight';
    src: local('HelveticaNow-ExtraLight'), url(./fonts/HelveticaNowText-ExtraLight.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-Medium';
    src: local('HelveticaNow-Medium'), url(./fonts/HelveticaNowText-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-MediumItalic';
    src: local('HelveticaNow-MediumItalic'), url(./fonts/HelveticaNowText-MediumItalic.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-RegIta';
    src: local('HelveticaNow-RegIta'), url(./fonts/HelveticaNowText-RegIta.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-Regular';
    src: local('HelveticaNow-Regular'), url(./fonts/HelveticaNowText-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-Thin';
    src: local('HelveticaNow-Thin'), url(./fonts/HelveticaNowText-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'HelveticaNow-ThinItalic';
    src: local('HelveticaNow-ThinItalic'), url(./fonts/HelveticaNowText-ThinItalic.ttf) format('truetype');
}
