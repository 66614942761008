.disabled-page a {
    background-color: #dee2e6;
    cursor: not-allowed;
}

.disabled-page a:hover {
    background-color: #dee2e6;
}

.page-link,
.page-link:hover {
    color: #1E4396EE;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #1E4396EE;
    border-color: #1E4396EE;
}